import { v4 as uuidv4 } from 'uuid';

class Data {
    constructor() {
        // 20241204
        this.debug = 0;
        this.api = 'https://oxwgbh6h3c.execute-api.ap-southeast-1.amazonaws.com/dev/cram-quiz';
        this.initData();
        this.q = {
            "quiz": []
        };
        this.r = {};
    }
    async initData() {
        console.log("initData");
        if (localStorage.getItem("uuid") && localStorage.getItem("passphase")) {
            console.log("uuid", localStorage.getItem("uuid"));
            this.uuid = localStorage.getItem("uuid");
            this.passphase = localStorage.getItem("passphase");
        } else {
            var passphase = uuidv4();
            this.passphase = passphase;    
            console.log("passphase", passphase);
            var userCreatePromise = new Promise((resolve, reject) => {
                resolve(this.userCreate());
            });
            var rep = await userCreatePromise;
            this.uuid = rep.uuid;
            localStorage.setItem("uuid", this.uuid);
            localStorage.setItem("passphase", this.passphase);
            console.log("uuid",this.uuid);
        }
        console.log("doUserSignon");
        var userSignonPromise = new Promise((resolve, reject) => {
            resolve(this.userSignon());
        });
        var rep = await userSignonPromise;
        this.token = rep.token;
        console.log("token", this.token);
        if (this.debug == 0) {
            var questionsPromise = new Promise((resolve, reject) => {
                resolve(this.getQuestions());
            });
            var rep = await questionsPromise;
            this.q = rep.questions;
        } else {
            this.q = [
                {
                    "n": 1,
                    "id": "602",
                    "q": "うすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいはうすいは",
                    "r": 0,
                    "t": "Adjective, い-adjective",
                    "o1": "kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri",
                    "o2": "suu",
                    "o3": "usui",
                    "o4": "itsu",
                    "a": "o3",
                    "e": "thin; weak"
                }
            ]
        }
        console.log("q", this.q);
    }
    async uploadResults() {
        var s = JSON.stringify(this.r);
        console.log("uploadResults", s);
        try {
            var body = {
                "action": "reply",
                "token": this.token,
                "reply": s
            }
            const response = await fetch(this.api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
                , body: JSON.stringify(body)
            });
            return await response.json();
        } catch (error) {
            console.error('Error uploading results:', error);
        }
    }
    async retry() {
        var questionsPromise = new Promise((resolve, reject) => {
            resolve(this.getQuestions());
        });
        var rep = await questionsPromise;
        this.q = rep.questions;
        console.log("retry q", this.q);
    }
    async userCreate() {
        try {            
            var body = {
                "action": "userCreate",
                "passphase": this.passphase
            }
            console.log("body",body);
            const response = await fetch(this.api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
                , body: JSON.stringify(body)
            });
            return await response.json();
        } catch (error) {
            console.error('Error creating user:', error);
        }
    }
    async userSignon() {
        console.log("userSignon");
        try {
            var body = {
                "action": "userSignon",
                "uuid": this.uuid,
                "passphase": this.passphase
            }
            console.log("body",body);
            const response = await fetch(this.api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
                , body: JSON.stringify(body)
            });
            return await response.json();
        } catch (error) {
            console.error('Error userSignon:', error);
        }
    }
    async getQuestions() {
        try {
            var body = {
                "action": "question",
                "token": this.token,
                "qt": "",
                "qc": 5
            }
            const response = await fetch(this.api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
                , body: JSON.stringify(body)
            });
            // console.log("getQuestions", response.json());
            return await response.json();
        } catch (error) {
            console.error('Error getQuestions:', error);
        }
    }
}
export default Data;