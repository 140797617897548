import React from 'react';
import Canvas from './Canvas'
import { useSearchParams } from "react-router-dom";
//import ClickResponse from './ClickResponse'

function App() {
    if (process.env.NODE_ENV === 'production') {
        console.log = () => {}
        console.error = () => {}
        console.debug = () => {}
    }
    let [searchParams] = useSearchParams();
    const quizid = searchParams.get("quizid");
    console.log("quizid", quizid);
    return <Canvas/>
    //return <ClickResponse/>
}

export default App