import QuizEntry from './QuizEntry';
import QuizTitle from './QuizTitle';
import QuizAsk from './QuizAsk';
import QuizResult from './QuizResult';
import Data from './Data';
import { Style, StyleName, KeyName } from './Style';

const ScreenState = {
  QUIZ_ENTRY: 0,
  QUIZ_TITLE: 1,
  QUIZ_INTRO: 2,
  QUIZ_ASK: 3,
  QUIZ_ANSWER: 4,
  QUIZ_RESULT: 5,
  QUIZ_OUTRO: 6,
  EXIT: 7
};

class Stage {
  constructor(canvas) {
    this.debug = 1;
    this.style = new Style(StyleName.HIDDEN);
    this.isShowFPS = true;
    this.canvas = canvas;
    this.context = canvas.getContext('2d');
    this.dpr = window.devicePixelRatio || 1;
    this.width = canvas.width * this.dpr;
    this.height = canvas.height * this.dpr;
    console.log("dpr", this.dpr);
    this.bg = new Image();
    // this.bg.src = '/background-001.jpg';
    this.bg.src = '/background-002.jpg';
    this.bgReady = false;
    this.bg.onload = () => {
      this.bgReady = true;
    };
    this.fps = 0.0;
    this.lastTime = 0.0;
    this.skipFrames = 100.0;
    this.currentFrame = 0;
    
    // handle mouse click
    this.canvas.addEventListener('click', this.doClick.bind(this));
    this.cursorTime = 10; // 10 frames
    this.cursorTimeout = 0;
    this.cursorX = 0;
    this.cursorY = 0;
    // define screens
    this.data = new Data();
    this.quiz = this.data.q.quiz;
    console.log("Stage::constructor", this.quiz);
    this.quizTitle = new QuizTitle(this, this.canvas);
    this.quizEntry = new QuizEntry(this, this.canvas);
    this.quizAsk = new QuizAsk(this, this.canvas);
    this.quizResult = new QuizResult(this, this.canvas);
    if (this.debug == 1) {
      this.screenState = ScreenState.QUIZ_ENTRY;
      this.data.debug = 1;
      this.quizAsk.debug = 1;
    } else {
      this.screenState = ScreenState.QUIZ_ENTRY;
    }
  }
  draw() {    
    //console.log("Stage::draw", this.canvas.width, this.canvas.height);
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    var ctx = this.context;
    // Scale all drawing operations by the dpr, so you
    // don't have to worry about the difference.
    // var dpr = window.devicePixelRatio || 1;
    ctx.scale(this.dpr, this.dpr);
    this.width = this.canvas.width / this.dpr;
    this.height = this.canvas.height / this.dpr;
    //console.log("Stage::draw", this.width, this.height);

    this.drawBackground();
    this.currentFrame++;
    if (this.isShowFPS) {
      if (this.currentFrame >= this.skipFrames) {
        this.updateFPS();
      }
      this.showFPS();
    }
    //this.context.lineWidth = 5;
    //this.context.beginPath();
    //this.context.moveTo(100, 100);
    //this.context.lineTo(200, 200);
    //this.context.stroke();
    if (this.screenState === ScreenState.QUIZ_ENTRY) {
        this.quizEntry.draw();
    } else if (this.screenState === ScreenState.QUIZ_TITLE) {
        this.quizTitle.draw();
    } else if (this.screenState === ScreenState.QUIZ_ASK) {
        this.quizAsk.draw();
    } else if (this.screenState === ScreenState.QUIZ_RESULT) {
        this.quizResult.draw();
    }
    this.drawCursor();
  }
  printWord(context, text, x, y, fitWidth, fitHeight, fontSize, lineHeightFactor, textAlign, textBaseline) {
    fitWidth = fitWidth || 0;
    if (textAlign == 'center') {
      x = x + fitWidth / 2;
    }
    // Split the text by newline character
    var lines = text.split('\n');
    var isFitHeight = 0;

    while(isFitHeight == 0) {
        var lineHeight = fontSize * lineHeightFactor;
        var currentLine = 1;
        var outputLines = [];
        this.context.font = `${fontSize}px Arial`;
        lines.forEach(function(line) {
            var idx = 0;
    
            while (idx < line.length) {
                var subLine = '';
                var width = 0;
    
                while (idx < line.length) {
                    var char = line[idx];
                    var tempLine = subLine + char;
                    var tempWidth = context.measureText(tempLine).width;
                    
                    if (tempWidth > fitWidth) {
                        break;
                    }
    
                    subLine = tempLine;
                    width = tempWidth;
                    idx++;
                }
    
                //context.fillText(subLine, x, y + (lineHeight * currentLine));
                outputLines.push([ subLine, x, y + (lineHeight * currentLine) ]);
                currentLine++;
            }
        });
        if (currentLine * lineHeight > fitHeight) {
            fontSize -= 1;
        } else {
            isFitHeight = 1;
        }
    }
    for (let i = 0; i < outputLines.length; i++) {
        var item = outputLines[i];
        context.textAlign = textAlign || 'left';
        context.textBaseline = textBaseline || 'top';
        context.fillText(item[0], item[1], item[2]);
    }  
  }
  drawBackground() {
    if (this.bgReady) {
      const canvasAspect = this.canvas.width / this.canvas.height;
      const imageAspect = this.bg.width / this.bg.height;
      let drawWidth, drawHeight;
      if (canvasAspect > imageAspect) {
        drawWidth = this.canvas.width;
        drawHeight = this.canvas.width / imageAspect;
      } else {
        drawWidth = this.canvas.height * imageAspect;
        drawHeight = this.canvas.height;
      }
      const offsetX = (this.canvas.width - drawWidth) / 2;
      const offsetY = (this.canvas.height - drawHeight) / 2;
  
      this.context.drawImage(this.bg, offsetX, offsetY, drawWidth, drawHeight);
    }
  }
  showFPS(){
    this.context.fillStyle = "#666666";
    this.context.font      = "normal 14px Arial";

    this.context.fillText(this.fps + " fps", 10, 10);    
  }
  updateFPS() {
    const newTime = performance.now();
    const delta = (newTime - this.lastTime) / 1000;
    //console.log(newTime, delta);
    this.fps = (this.skipFrames / delta).toFixed(2);
    this.lastTime = newTime;
    this.currentFrame = 0;
  }
  drawCursor(){
    if (this.cursorTimeout > 0) {
        this.context.beginPath();
        this.context.arc(this.cursorX, this.cursorY, 5, 0, 2 * Math.PI); // Draw a dot with radius 3
        const alpha = Math.max(0, Math.min(1, this.cursorTimeout / this.cursorTime));
        this.context.fillStyle = `rgba(0, 0, 0, ${alpha})`
        this.context.fill();
        this.cursorTimeout--;
        if (this.cursorTimeout <= 0) {
            this.cursorX = 0;
            this.cursorY = 0;
        }
    }
  }
  clearCanvas() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  drawRectangle(x, y, width, height, color) {
    this.context.fillStyle = color;
    this.context.fillRect(x, y, width, height);
  }
  doClick(event) {
    const x = event.clientX / this.dpr;
    const y = event.clientY / this.dpr;
    console.log("click", x, y);
    this.cursorTimeout = this.cursorTime;
    this.cursorX = x;
    this.cursorY = y;
    if (this.screenState === ScreenState.QUIZ_ENTRY) {
      this.quizEntry.doClick(x, y);
    } else if (this.screenState === ScreenState.QUIZ_TITLE) {
      this.quizTitle.doClick(x, y);
    } else if (this.screenState === ScreenState.QUIZ_ASK) {
      this.quizAsk.doClick(x, y);
    } else if (this.screenState === ScreenState.QUIZ_RESULT) {
      this.quizResult.doClick(x, y);
    }
  }
  goAsk() {
    this.quizAsk.init();
    this.screenState = ScreenState.QUIZ_ASK;
  }
  goResult() {
    this.quizResult.init();
    this.screenState = ScreenState.QUIZ_RESULT;
  }
}

export default Stage;
export { ScreenState };