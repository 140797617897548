import {Stage, ScreenState } from './Stage';
import { KeyName } from './Style';

const ButtonStatus = {
    NORMAL: 0,
    CORRECT: 1,
    INCORRECT: 2,
}
const ANSWER_TIMEOUT = 1000;
const AskState = {
    ASK: 0,
    ANSWER: 1,
}
class QuizAsk {
    /**
     * @param {Stage} stage - The stage object
     * @param {HTMLCanvasElement} canvas - The canvas element
     */
    constructor(stage, canvas) {
        this.debug = 0;
        this.stage = stage;
        console.log("QuizAsk::dpr", stage.dpr);
        console.log("QuizAsk::height: ", stage.height);
        console.log("QuizAsk::width: ", stage.width);
        this.canvas = canvas;
        this.context = canvas.getContext('2d');
        //this.questions = questions;
    }
    init() {
        this.questions = this.stage.data.q;
        console.log("QuizAsk::questions", this.questions);
        this.totalQuestions = this.questions.length;
        this.currentQuestion = 1;
        if (this.totalQuestions > 0) {
            this.setupQuestion()
            this.stage.data.r = {
                response: []
            };
        } else {

        }
    }
    setupQuestion() {
        this.askState = AskState.ASK;
        this.askTime = performance.now();
        this.questionText = {
            x: 50,
            y: 50,
            width: 400,
            height: 50,
            n: this.currentQuestion.n,
            id: this.currentQuestion.id,
            text: this.currentQuestion + ". " + this.questions[this.currentQuestion - 1].q,
        }
        this.buttons = [];
        for (let i = 0; i < 4; i++) {
            this.buttons.push({
                x: (this.stage.width / 2) - 200,
                y: (this.stage.height / 2) - 200 + 50 * i,
                width: 400,
                height: 50,
                text: this.questions[this.currentQuestion - 1]['o' + (i + 1)],
                correct: (parseInt(this.questions[this.currentQuestion - 1].a.substring(1), 10) === (i + 1)),
                explanation: this.questions[this.currentQuestion - 1].e,
                status: ButtonStatus.NORMAL,
            });
        }
        console.log("QuizAsk::buttons", this.buttons);
    }

    answerQuestion(answer) {
        if (this.askState === AskState.ASK) {
            const b = this.buttons[answer];
            this.askState = AskState.ANSWER;
            this.answerTime = performance.now() + ANSWER_TIMEOUT;
            var r = {
                n: this.currentQuestion,
                id: this.questions[this.currentQuestion - 1].id,
                a: b.text,
                t: performance.now() - this.askTime,
            }
            if (b.correct) {
                console.log('Correct!');
                r.score = 1;
                this.buttons[answer].status = ButtonStatus.CORRECT;
                this.questionText.text = this.questionText.text + '\n' + this.questions[this.currentQuestion - 1].e;
            } else {
                console.log('Incorrect!');
                r.score = 0;
                this.buttons[answer].status = ButtonStatus.INCORRECT;
                this.questionText.text = this.questionText.text + '\n' + this.questions[this.currentQuestion - 1].e;
                for (let j = 0; j < 4; j++) {
                    if (this.buttons[j].correct) {
                        this.buttons[j].status = ButtonStatus.CORRECT;
                    }
                }
            }
            this.stage.data.r.response.push(r);
        }
    }
    nextState() {
        if (this.askState === AskState.ANSWER && this.answerTime && performance.now() > this.answerTime) {
            this.currentQuestion++;
            if (this.currentQuestion <= this.totalQuestions) {
                this.setupQuestion();
            } else {
                this.stage.goResult();
            }
        }
    }
    draw() {
        this.screenResize();
        this.drawQuestion();
        this.drawButtons();
        this.nextState();
    }
    screenResize() {
        if (this.stage.height !== this.stageHeight || this.stage.width !== this.stageWidth) {
            this.stageHeight = this.stage.height;
            this.stageWidth = this.stage.width;
            this.marginTop = this.stage.width * 0.1;
            this.marginLeft = this.marginTop;
            this.context.fillStyle = this.stage.style.getColor(KeyName.QUESTION_TEXT);
            this.fontHeight = 25;
            this.fitHeight = (this.stage.height / 2) - this.marginTop;
            this.fitWidth = this.stage.width - (this.marginLeft * 2);
            this.buttonColors = [
                this.stage.style.getColor(KeyName.BUTTON_BOX),
                this.stage.style.getColor(KeyName.CORRECT_BOX),
                this.stage.style.getColor(KeyName.INCORRECT_BOX),
            ];
            this.textColor = [
                this.stage.style.getColor(KeyName.BUTTON_TEXT),
                this.stage.style.getColor(KeyName.CORRECT_TEXT),
                this.stage.style.getColor(KeyName.INCORRECT_TEXT),
            ];
        }
    }
    drawQuestion() {
        this.stage.printWord(this.context, this.questionText.text, this.marginTop, this.marginLeft, this.fitWidth, this.fitHeight, this.fontHeight, 1.4, 'left', 'top');
        
    }
    drawImage(image,x,y,scale,rot){
        this.canvas.setTransform(scale,0,0,scale,x,y);
        this.canvas.rotate(rot);
        this.canvas.drawImage(image,-image.width / 2, -image.height / 2);
    }
    drawButtons() {
        for (let i = 0; i < 4; i++) {
            this.buttons[i].width = this.stageWidth * 0.8;
            this.buttons[i].height = this.stageHeight / 12;
            this.buttons[i].x = this.stageWidth * 0.1;
            this.buttons[i].y = (this.stageHeight / 2) + (this.buttons[i].height * i);
            var text = this.buttons[i].text;
            var status = this.buttons[i].status;
            this.context.fillStyle = this.buttonColors[status];
            this.context.fillRect(this.buttons[i].x, this.buttons[i].y, this.buttons[i].width, this.buttons[i].height);
            this.context.fillStyle = this.textColor[status];
            this.context.font = '20px Arial';
            //this.context.textAlign = 'center';
            //this.context.textBaseline = 'middle';
            // this.context.fillText(text, x + width / 2, y + height / 2);
            this.stage.printWord(this.context, text, this.buttons[i].x, this.buttons[i].y, this.buttons[i].width, this.buttons[i].height, this.fontHeight, 1.4, 'center', 'middle');
        
        }
    }

    doClick(x, y) {
        for (let i = 0; i < 4; i++) {
            const b = this.buttons[i];
            if (
                x >= b.x &&
                x <= b.x + b.width &&
                y >= b.y &&
                y <= b.y + b.height
            ) {
            console.log('Button clicked!');
            this.answerQuestion(i)
            console.log(b.explanation);
            }
        }
      }
};

export default QuizAsk;