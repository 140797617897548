import React, { useRef, Component } from 'react';
import anime from 'animejs';
import Stage from './Stage';

// Define the initial state
const initialState = {
    // Add your initial state properties here
    clicks: 0,
    animations: []
};
class Canvas extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.requestIdRef = React.createRef();
        this.dpr = window.devicePixelRatio || 1;
        this.state = {
            cW: window.innerWidth,
            cH: window.innerHeight,
            bgColor: "#FF6138",
            animations: [],
            circles: [],
        };
        this.colorPicker = this.createColorPicker();
        this.addClickListeners = this.addClickListeners.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.removeAnimation = this.removeAnimation.bind(this);
        this.calcPageFillRadius = this.calcPageFillRadius.bind(this);
        this.resizeCanvas = this.resizeCanvas.bind(this);
    }

    componentDidMount() {
        this.canvas = this.canvasRef.current;
        if (this.canvas) {
            this.stage = new Stage(this.canvas);        
            this.addClickListeners();
            this.resizeCanvas();
            window.addEventListener("resize", this.resizeCanvas);
            const animate = () => {
                this.stage.draw();
                this.requestIdRef.current = requestAnimationFrame(animate);
            };
            this.animate();
            this.handleInactiveUser();
        } else {
            console.log("Canvas::componentDidMount no canvas", this.canvas);
        } // test
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.requestIdRef.current);
    }

    addClickListeners() {
        document.addEventListener("touchstart", this.handleEvent);
        document.addEventListener("mousedown", this.handleEvent);
    }
    createColorPicker() {
        const colors = ["#FF6138", "#FFBE53", "#2980B9", "#282741"];
        let index = 0;
        return {
        next: () => {
            index = index < colors.length - 1 ? index + 1 : 0;
            return colors[index];
        },
        current: () => colors[index],
        };
    }
    handleEvent(e) {
        if (e.touches) {
            e.preventDefault();
            e = e.touches[0];
        }
        const currentColor = this.colorPicker.current();
        const nextColor = this.colorPicker.next();
        const targetR = this.calcPageFillRadius(e.pageX, e.pageY);
        const rippleSize = Math.min(200, (this.state.cW * .4));
        const minCoverDuration = 750;
    
        const pageFill = new this.Circle({
            x: e.pageX,
            y: e.pageY,
            r: 0,
            fill: nextColor,
        });
    
        const fillAnimation = anime({
            targets: pageFill,
            r: targetR,
            duration: Math.max(targetR / 2, minCoverDuration),
            easing: "easeOutQuart",
            complete: () => {
                //this.setState({ bgColor: pageFill.fill });
                this.removeAnimation(fillAnimation);
            },
        });
    
        const ripple = new this.Circle({
            x: e.pageX,
            y: e.pageY,
            r: 0,
            fill: currentColor,
            stroke: { width: 3, color: currentColor },
            opacity: 1,
        });
    
        const rippleAnimation = anime({
            targets: ripple,
            r: rippleSize,
            opacity: 0.1,
            easing: "easeOutExpo",
            duration: 10,
            complete: this.removeAnimation,
        });
    
        const particles = [];
        for (let i = 0; i < 32; i++) {
            const particle = new this.Circle({
                x: e.pageX / this.dpr,
                y: e.pageY / this.dpr,
                fill: currentColor,
                r: anime.random(24, 48),
            });
            particles.push(particle);
        }
    
        const particlesAnimation = anime({
            targets: particles,
            x: particle => particle.x + anime.random(rippleSize, -rippleSize),
            y: particle => particle.y + anime.random(rippleSize * 1.15, -rippleSize * 1.15),
            r: 0,
            easing: "easeOutExpo",
            duration: anime.random(1000, 1300),
            complete: this.removeAnimation,
        });
    
        this.setState(prevState => ({
            animations: [...prevState.animations, rippleAnimation, particlesAnimation],
        }));
    }
    
    removeAnimation(animation) {
        this.setState(prevState => ({
            animations: prevState.animations.filter(anim => anim !== animation),
        }));
    }
    
    calcPageFillRadius(x, y) {
        const l = Math.max(x - 0, this.state.cW - x);
        const h = Math.max(y - 0, this.state.cH - y);
        return Math.sqrt(l ** 2 + h ** 2);
    }
    
    resizeCanvas() {
        this.setState({
            cW: window.innerWidth,
            cH: window.innerHeight,
    }, () => {
            const canvas = this.canvasRef.current;
            const ctx = canvas.getContext("2d");
            canvas.width = this.state.cW * window.devicePixelRatio;
            canvas.height = this.state.cH * window.devicePixelRatio;
            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
        });
      }
    
    Circle = function (opts) {
        Object.assign(this, opts);
        this.draw = function(ctx) {
            ctx.globalAlpha = this.opacity || 1;
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false);
            if (this.stroke) {
                ctx.strokeStyle = this.stroke.color;
                ctx.lineWidth = this.stroke.width;
                ctx.stroke();
            }
            if (this.fill) {
                ctx.fillStyle = this.fill;
                ctx.fill();
            }
            ctx.closePath();
            ctx.globalAlpha = 1;
        }
    }
    
    
    animate() {
        //console.log("ClickResponse::animate");
        // this.stage.draw();
        anime({
            duration: Infinity,
            update: () => {
                this.stage.draw();
                const canvas = this.canvasRef.current;
                //console.log("ClickResponse::canvas", canvas);
                const ctx = canvas.getContext("2d");
                //console.log("ClickResponse::animate", ctx);
                //ctx.fillStyle = this.state.bgColor;
                //ctx.fillRect(0, 0, this.state.cW, this.state.cH);
                this.state.animations.forEach(anim => {
                    anim.animatables.forEach(animatable => {
                        animatable.target.draw(ctx);
                    });
                });
          },
        });
        //this.requestIdRef.current = requestAnimationFrame(this.animate.bind(this));
      }
    
    handleInactiveUser() {
        const inactive = setTimeout(() => {
          this.fauxClick(this.state.cW / 2, this.state.cH / 2);
        }, 2000);
    
        const clearInactiveTimeout = () => {
          clearTimeout(inactive);
          document.removeEventListener("mousedown", clearInactiveTimeout);
          document.removeEventListener("touchstart", clearInactiveTimeout);
        };
    
        document.addEventListener("mousedown", clearInactiveTimeout);
        document.addEventListener("touchstart", clearInactiveTimeout);
    }
    
    startFauxClicking() {
        setTimeout(() => {
          this.fauxClick(anime.random(this.state.cW * .2, this.state.cW * .8), anime.random(this.state.cH * .2, this.state.cH * .8));
          this.startFauxClicking();
        }, anime.random(200, 900));
    }
    
    fauxClick(x, y) {
        const fauxClick = new Event("mousedown");
        fauxClick.pageX = x;
        fauxClick.pageY = y;
        document.dispatchEvent(fauxClick);
    }
    render() {
    return (
        <>
            <style>
              {`html, body {
margin: 0;
padding: 0;
height: 100%;
}
#myCanvas {
display: block; /* Remove any inline spacing */
width: 100%;
height: 100%;
}
              `}
            </style>
            <canvas id="myCanvas" ref={this.canvasRef}/>
        </>
    );
  }
}

export default Canvas;
