const StyleName = {
    NORMAL: 0,
    HIDDEN: 1,
    NIGHT: 2,
}
const KeyName = {
    BACKGROUND: 0,
    FOREGROUND: 1,
    CURSOR: 2,
    DEBUG_TEXT: 3,
    QUESTION_TEXT: 4,
    BUTTON_BOX: 5,
    BUTTON_TEXT: 6,
    CORRECT_BOX: 7,
    CORRECT_TEXT: 8,
    INCORRECT_BOX: 9,
    INCORRECT_TEXT: 10,
}
const StyleColor = [
    [
        '#FFFFFF', '#000000', '#000000', '#000000', '#333333', '#007BFF', '#FFFFFF', '#28A745', '#FFFFFF', '#DC3545', '#FFFFFF'
    ],
    [
        '#FFFFFF', '#808080', '#808080', '#808080', '#808080', '#EAE4FF', '#808080', '#FBFDEE', '#808080', '#F5D6D1', '#808080'
    ],
    [
        '#FFFFFF', '#000000', '#000000', '#000000', '#333333', '#007BFF', '#FFFFFF', '#28A745', '#FFFFFF', '#DC3545', '#FFFFFF'
    ],
]

class Style {
    constructor(defaultStyle) {
        this.style = defaultStyle;
    }
    getColor(key) {
        return StyleColor[this.style][key];
    }
}

export { Style, StyleName, KeyName };