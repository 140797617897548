import {Stage, ScreenState } from './Stage';
import { KeyName } from './Style';

class QuizResult {
    /**
     * @param {Stage} stage - The stage object
     * @param {HTMLCanvasElement} canvas - The canvas element
     */
    constructor(stage, canvas) {
        this.stage = stage;
        console.log("QuizResult::dpr", stage.dpr);
        console.log("QuizResult::height: ", stage.height);
        console.log("QuizResult::width: ", stage.width);
        this.canvas = canvas;
        this.context = canvas.getContext('2d');
        this.button = {
            x: (this.stage.width / 2) - 200,
            y: this.stage.height / 2 - 25,
            width: 200,
            height: 50,
            text: 'Retry',
        };
    }    
    init() {
        this.stage.data.uploadResults();
        var n = this.stage.data.q.length;
        // traiditional way to calculate correct answers
        var correct = 0;
        for (var i = 0; i < n; i++) {
            correct+=this.stage.data.r.response[i].score;
        }   
        this.text = "You got " + correct + " out of " + n + " correct!";
        if (this.stage.debug == 1) {
            this.text += " kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri kusuri";
        }
    }
    draw() {
        this.screenResize();
        this.drawButton();
        this.drawResult();
        
    }
    screenResize() {
        if (this.stage.height !== this.stageHeight || this.stage.width !== this.stageWidth) {
            this.stageHeight = this.stage.height;
            this.stageWidth = this.stage.width;
            this.marginTop = this.stage.width * 0.1;
            this.marginLeft = this.marginTop;
            this.context.fillStyle = this.stage.style.getColor(KeyName.QUESTION_TEXT);
            this.fontHeight = 25;
            this.fitHeight = (this.stage.height / 2) - this.marginTop;
            this.fitWidth = this.stage.width - (this.marginLeft * 2);
            this.buttonColors = [
                this.stage.style.getColor(KeyName.BUTTON_BOX),
                this.stage.style.getColor(KeyName.CORRECT_BOX),
                this.stage.style.getColor(KeyName.INCORRECT_BOX),
            ];
            this.textColor = [
                this.stage.style.getColor(KeyName.BUTTON_TEXT),
                this.stage.style.getColor(KeyName.CORRECT_TEXT),
                this.stage.style.getColor(KeyName.INCORRECT_TEXT),
            ];
        }
    }
    drawButton() {
        const { x, y, width, height, text } = this.button;
        this.button.x = (this.stage.width / 2 - 100);
        this.button.y = this.stage.height - 150;
        this.context.fillStyle = this.stage.style.getColor(KeyName.BUTTON_BOX);
        this.context.fillRect(this.button.x, this.button.y, width, height);
        this.context.fillStyle = this.stage.style.getColor(KeyName.BUTTON_TEXT);
        this.context.font = '20px Arial';
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        this.context.fillText(text, this.button.x + width / 2, this.button.y + height / 2);
    }
    drawResult() {
        //this.context.fillStyle = this.stage.style.getColor(KeyName.QUESTION_TEXT);
        //this.context.font = '30px Arial';
        //this.context.textAlign = 'center';
        //this.context.textBaseline = 'middle';
        //this.context.fillText(this.text, this.stage.width / 2, 50);
        this.stage.printWord(this.context, this.text, this.marginLeft, this.marginTop, this.fitWidth, this.fitHeight, this.fontHeight, 1.4, 'center', 'top');
      
    }

    doClick(x, y) {        
        if (
          x >= this.button.x &&
          x <= this.button.x + this.button.width &&
          y >= this.button.y &&
          y <= this.button.y + this.button.height
        ) {
          console.log('Retry clicked!');
          this.stage.screenState = ScreenState.QUIZ_TITLE;
          this.stage.data.retry();
        }
      }
};

export default QuizResult;