import {Stage, ScreenState } from './Stage';
import { KeyName } from './Style';

class QuizTitle {
    /**
     * @param {Stage} stage - The stage object
     * @param {HTMLCanvasElement} canvas - The canvas element
     */
    constructor(stage, canvas) {
        this.stage = stage;
        console.log("QuizTitle::dpr", stage.dpr);
        console.log("QuizTitle::height: ", stage.height);
        console.log("QuizTitle::width: ", stage.width);
        this.canvas = canvas;
        this.context = canvas.getContext('2d');
        this.button = {
            x: (this.stage.width / 2) - 200,
            y: this.stage.height / 2 - 25,
            width: 200,
            height: 50,
            text: 'Start',
        };
    }    
    draw() {
        this.drawButton();
        this.drawTitle();
        
    }
    drawButton() {
        const { x, y, width, height, text } = this.button;
        this.button.x = (this.stage.width / 2 - 100);
        this.button.y = this.stage.height - 150;
        this.context.fillStyle = this.stage.style.getColor(KeyName.BUTTON_BOX);
        this.context.fillRect(this.button.x, this.button.y, width, height);
        this.context.fillStyle = this.stage.style.getColor(KeyName.BUTTON_TEXT);
        this.context.font = '20px Arial';
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        this.context.fillText(text, this.button.x + width / 2, this.button.y + height / 2);
    }
    drawTitle() {
        this.context.fillStyle = this.stage.style.getColor(KeyName.QUESTION_TEXT);
        this.context.font = '30px Arial';
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        this.context.fillText('Quiz: Test 1', this.stage.width / 2, 50);
    }

    doClick(x, y) {        
        if (
          x >= this.button.x &&
          x <= this.button.x + this.button.width &&
          y >= this.button.y &&
          y <= this.button.y + this.button.height
        ) {
          console.log('Button clicked!');
          this.stage.goAsk();
        }
      }
};

export default QuizTitle;